

































import Vue from 'vue'
import LegalDocumentCell from './LegalDocumentCell.vue'
// @ts-ignore
import corpTileClassMixin from '@utils/mixins/addy-plus-corporation-tile-class-mixin.js'
import { IComputed, IProps, IMethods } from '@/types/addy_plus/corporations/OneEntityUI'

export default Vue.extend<unknown, IMethods, IComputed, IProps>({
  props: {
    entity: {
      type: Object,
      required: true,
    },
    basicColumns: {
      type: Object,
      required: true,
    },
    legalDocumentColumns: {
      type: Array,
      required: true,
    },
  },
  mixins: [corpTileClassMixin],
  components: {
    LegalDocumentCell,
  },
  computed: {
    truncatedBasicColumns() {
      const columns = JSON.parse(JSON.stringify(this.basicColumns))
      delete columns.name
      return columns
    },
  },
})
