export default {
  methods: {
    generateClass(entity, key) {
      let klass = 'has-text-centered '
      switch (key) {
      case 'name':
        klass += 'is-sticky is-clickable'
        break
      case 'businessNumber':
        klass += 'is-clickable '
        klass += entity[key] && entity.hasBusinessNumberBackup ? 'background-green' : 'background-red'
        break
      default:
        klass += entity[key] ? 'background-green' : 'background-red is-clickable'
      }
      return klass
    },
  },
}
