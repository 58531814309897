













import Vue from 'vue'
import { IProps } from '@/types/addy_plus/corporations/LegalDocumentCell'

export default Vue.extend<unknown, unknown, unknown, IProps>({
  props: {
    fileHashList: {
      required: true,
    },
  },
})
