<template>
  <div class="addy-plus-corporation-list">
    <Permission :overwrite="['create', 'read']"/>
    <div class="title">Legal Entities</div>
    <template v-if="corporations">
      <div v-if="!corporations.length">
        There is no legal entity yet. Click
        <span class="has-text-primary is-clickable has-text-weight-semibold" @click="addEntity">HERE</span>
        to create one.
      </div>
      <div v-else-if="corporations.length === 1" class="pb-6">
        <OneEntity
          :entity="corporations[0]"
          :basicColumns="basicColumns"
          :legalDocumentColumns="legalDocumentColumns"
          @onBasicColumnClick="(corp, basicKey) => onRowClick(corp, basicKey)"
          @onLegalColumnClick="(corp, legalLabel) => onLegalDocView(corp, legalLabel)"
        ></OneEntity>
      </div>
      <div v-else class="table-wrapper">
        <table class="table is-bordered is-fullwidth">
          <thead>
            <tr>
              <th class="is-sticky" rowspan="2">
                <div>Entity Name</div>
              </th>
              <th colspan="5">
                <div>Basic Information</div>
              </th>
              <th colspan="44">
                <div>General Legal Documents</div>
              </th>
            </tr>
            <tr>
              <th
                v-for="label in subheadings"
                :key="'th' + label"
                :class="label === 'Entity Name' ? 'is-sticky' : ''"
              >{{label}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="corp in corporations" :key="corp.id">
              <td
                v-for="(basicLabel, basicKey) in basicColumns"
                :key="'td' + basicKey"
                :class="generateClass(corp, basicKey)"
                @click="onRowClick(corp, basicKey)"
              >
                {{corp[basicKey]}}
              </td>
              <td
                v-for="legalLabel in legalDocumentColumns"
                :key="'td' + legalLabel"
                :class="`legal-doc has-text-centered is-clickable ${corp.corporationDocuments[legalLabel] ? 'background-green' : 'background-red'}`"
                @click="onLegalDocView(corp, legalLabel)"
              >
                <LegalDocumentCell
                  :fileHashList="corp.corporationDocuments[legalLabel]"
                ></LegalDocumentCell>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import corpTileClassMixin from '@utils/mixins/addy-plus-corporation-tile-class-mixin.js'
import { previewFile, ascSort } from '@utils/common-methods/common'
import { fetchCorporations, fetchLegalDocumentTypes as fetchDocumentTypes } from '@api/addy-plus/corporations'
import { fetchDocUrl } from '@/api/common'
import Permission from '@views/addy_plus/base/Permission.vue'
import OneEntity from './components/OneEntityUI.vue'
import LegalDocumentCell from './components/LegalDocumentCell.vue'
const basicColumnMap = {
  name: 'Entity Name',
  incorporationNumber: 'Incorporation Number',
  incorporationDate: 'Incorporation Date',
  businessNumber: 'Business Number',
  financialYearEndOn: 'Financial Year End',
  authorizedShareCapital: 'Authorized Share Capital',
}

export default {
  title: 'Corporations',
  mixins: [pageTitleMixin, corpTileClassMixin],
  components: {
    Permission,
    OneEntity,
    LegalDocumentCell,
  },
  data() {
    return {
      basicColumns: Object.freeze(basicColumnMap),
      legalDocumentColumns: [],
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['corporations']),
    accountId() {
      return this.$route.params.accountId
    },
    subheadings() {
      return Object.values(this.basicColumns).filter((lab) => lab !== 'Entity Name').concat(this.legalDocumentColumns)
    },
  },
  watch: {
    accountId() {
      this.fetchCorps()
    },
  },
  mounted() {
    this.fetchCorps()
    this.fetchLegalDocumentTypes()
  },
  methods: {
    ...mapActions('addyPlusBase', ['setCorporations']),
    fetchCorps() {
      fetchCorporations(this.accountId).then((result) => {
        if (!result.success) return
        const corporations = ascSort(result.data, 'name')
        this.setCorporations(corporations)
      })
    },
    fetchLegalDocumentTypes() {
      fetchDocumentTypes().then((result) => {
        if (!result.success) return
        this.legalDocumentColumns = result.data
      })
    },
    onRowClick(row, key) {
      const route = `/addyPlus/${this.accountId}/corporations/${row.id}`

      switch (key) {
      case 'name':
        this.$router.push(route)
        break
      case 'businessNumber':
        if (row[key] && row.hasBusinessNumberBackup) {
          this.fetchBusinessNumberBackup(row.id)
        } else {
          this.$router.push(`${route}?target=${row[key] ? 'businessNumberBackup' : key}`)
        }
        break
      default:
        if (row[key]) return
        this.$router.push(`${route}?target=${key}`)
      }
    },
    onLegalDocView(row, fileType) {
      /**
       * if single file (image or pdf), open a new tap to preview
       * if single file (not browser viewable), download the file
       * if multiple files, navigate to entity data room with auto scrolling
       * if file is missing, navigate to entity data room to upload
       */
      const hashids = row.corporationDocuments[fileType]
      const baseRoute = `/addyPlus/${this.accountId}/corporations/${row.id}/dataRoom`

      if (!hashids) this.$router.push(`${baseRoute}?category=${fileType}`)
      else if (hashids.length === 1) this.viewLegalDoc(hashids[0])
      else window.open(`${location.origin}${baseRoute}?target=${hashids[0]}`)
    },
    viewLegalDoc(hashid) {
      this.fetchDocument(hashid, 'EncryptedUpload', 'file')
    },
    fetchDocument(hashid, modelName, documentType) {
      fetchDocUrl({
        hashid: hashid,
        model_name: modelName,
        document_type: documentType,
      }).then((res) => {
        if (res.success) previewFile(res.data.url)
      })
    },
    fetchBusinessNumberBackup(corporationId) {
      this.fetchDocument(corporationId, 'Corporation', 'business_number_backup')
    },
    addEntity() {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/new`)
    },
  },
}
</script>
<style lang="scss" scoped>
$borderColor: #dbdbdb;
.addy-plus-corporation-list {
  font-size: 16px;
  height: 100%;
  .table-wrapper {
    max-height: calc(100% - 60px);
    overflow: auto;
    border-top: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    .table {
      .is-sticky {
        min-width: 250px;
        border-left: none;
      }
      thead {
        position: sticky;
        top: 0;
        z-index: 9;
        tr:first-child th {
          border-top: none;
          border-bottom: none;
          padding: 0;
          &:nth-of-type(2) {
            border-left: none;
          }
          div {
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid $borderColor;
          }
          &.is-sticky {
            border-right: none;
            div {
              height: 100%;
              line-height: 161px;
              border-right: 1px solid $borderColor;
            }
          }
        }
        tr:last-child th {
          border-top: none;
          &:first-of-type {
            border-left: none;
          }
        }
        th {
          vertical-align: middle;
          text-align: center;
          font-weight: 600;
          padding: 12px 12px;
          background-color: #fff;
          &.is-sticky {
            background-color: var(--addy-blue-light-3);
          }
        }
      }
      tbody {
        tr:hover td {
          &.is-sticky {
            background-color: var(--addy-blue-light-2);
          }
          &.background-green {
            background-color: #9ac99e;
          }
          &.background-red {
            background-color: #ea9da8;
          }
        }
        td {
          padding: 10px 12px;
          min-width: 160px;
          vertical-align: middle;
          &.is-sticky {
            background-color: var(--addy-blue-light-3);
          }
          &.background-green {
            background-color: #64cd6d;
          }
          &.background-red {
            background-color: #ec4c64;
          }
        }
        tr:first-child td.is-sticky {
          border-top: none;
        }
      }
    }
  }
}
</style>
